<template>
  <div
    v-if="metadata"
    class="flex -bottom-20 w-full flex-1 p-5 bg-white justify-between items-center text-sm text-gray-500 font-medium shadow-sm"
    :class="{ absolute: isAbsolute }"
    data-cy="pagination"
  >
    <!-- to fake flex between -->
    <div v-if="!showPerPage" />

    <div v-if="showPerPage" class="flex space-x-2">
      <span class="text-headline transform translate-y-1">Items per page:</span>
      <lf-dropdown
        class="h-3"
        name="items_per_page"
        :hidePlaceholderWhenValue="true"
        :options="ITEMS_PER_PAGE"
        @change="changeItemsPerPage"
        :value="metadata.per_page || 15"
        :dropUp="true"
      />
    </div>
    <div class="flex items-center" v-if="metadata.last_page">
      <span class="mr-8" data-cy="total">Total: {{ metadata.total }}</span>
      <a
        class="pagination-arrow pagination-arrow__left mr-2 cursor-pointer"
        v-show="metadata.current_page > 1"
        @click.prevent="
          metadata?.current_page && changePage(metadata.current_page - 1)
        "
      >
        &nbsp;
      </a>
      <a
        @click.prevent="changePage(1)"
        :class="{ 'bg-gray-100': 1 == metadata.current_page }"
        class="border rounded border-gray-100 px-2 py-1 ml-2 cursor-pointer"
      >
        1
      </a>
      <a
        v-if="metadata.current_page > 4"
        class="border rounded border-gray-100 px-2 py-1 ml-2"
      >
        ...
      </a>
      <a
        v-for="page in pageNumbersToShow"
        :key="page"
        @click.prevent="changePage(page)"
        :class="{ 'bg-gray-100': page == metadata.current_page }"
        class="border rounded border-gray-100 px-2 py-1 ml-2 cursor-pointer"
      >
        {{ page }}
      </a>
      <a
        v-if="metadata.current_page + 3 < metadata.last_page"
        class="border rounded border-gray-100 px-2 py-1 ml-2"
      >
        ...
      </a>
      <a
        v-if="metadata.last_page > 1"
        @click.prevent="metadata?.last_page && changePage(metadata.last_page)"
        :class="{
          'bg-gray-100': metadata.last_page == metadata.current_page
        }"
        class="border rounded border-gray-100 px-2 py-1 ml-2 cursor-pointer"
      >
        {{ metadata.last_page }}
      </a>
      <a
        class="pagination-arrow pagination-arrow__right ml-4 cursor-pointer"
        v-show="metadata.current_page != metadata.last_page"
        @click.prevent="
          metadata?.current_page && changePage(metadata.current_page + 1)
        "
      >
        &nbsp;
      </a>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import type { PropType } from "vue";
import range from "lodash/range";
import type { IResponseMeta } from "@/models/common";

import LfDropdown from "../inputs/LfDropdown.vue";

const emit = defineEmits<{
  (event: "changePage", value: number): void;
  (event: "changeItemsPerPage", perPage: number, maxPage: number | null): void;
}>();

const props = defineProps({
  metadata: {
    type: Object as PropType<IResponseMeta | null>,
    required: true
  },
  showPerPage: {
    type: Boolean,
    required: true
  },
  isAbsolute: {
    type: Boolean,
    default: true
  }
});

const ITEMS_PER_PAGE = {
  15: "15",
  25: "25",
  50: "50",
  100: "100"
};

const PAGINATION_NUMBERS_SPACING = 2;
const PAGINATION_INITIAL_START = 2;

const pageNumbersToShow = computed(() => {
  if (!props.metadata) {
    return [];
  }
  const { current_page, last_page } = props.metadata;
  if (last_page <= 2) {
    // It means that there's nothing in between
    return [];
  }

  let from = current_page - PAGINATION_NUMBERS_SPACING;
  let to = current_page + PAGINATION_NUMBERS_SPACING;

  if (from <= 1) {
    from = PAGINATION_INITIAL_START;
  }

  if (to >= last_page) {
    to = last_page - 1;
  }

  return range(from, to + 1);
});

const changePage = (page: number) => {
  emit("changePage", page);
};

const changeItemsPerPage = (itemsPerPage: number | string) => {
  const val = Number(itemsPerPage);
  if (!props.metadata) {
    return;
  }
  const maxPage = Math.ceil(props.metadata.total / val);
  if (maxPage < props.metadata.current_page) {
    emit("changeItemsPerPage", val, maxPage);
  } else {
    emit("changeItemsPerPage", val, null);
  }
};
</script>
<style scoped>
.pagination-arrow {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
}

.pagination-arrow__left {
  border-right: 6px solid #d4d8e2;
}
.pagination-arrow__right {
  border-left: 6px solid #d4d8e2;
}
</style>
